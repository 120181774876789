<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1300px" top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" :label-width="$t('workOrder.labelWidth')" :model="workOrderRecord"
        :rules="ruleValidate">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane :label="$t('workOrder.baseInfo')" name="first">
            <div class="vm-separate">
              <el-steps :active="stepActive" align-center>
                <el-step v-if="workOrderRecord.recordState >= 0" :title="$t('workOrder.alarmReported')"
                  :description="workOrderRecord.alarmTime"></el-step>
                <el-step v-else :title="$t('workOrder.notReported')"></el-step>

                <el-step v-if="workOrderRecord.recordState >= 1" :title="$t('workOrder.alarmed')"
                  :description="workOrderRecord.acceptTime"></el-step>
                <el-step v-else :title="$t('workOrder.unAlarmed')"></el-step>

                <el-step v-if="workOrderRecord.recordState >= 2 && workOrderRecord.workOrderType === 1"
                  :title="$t('workOrder.arrived')">
                  <template v-slot:description>
                    <div v-if="workOrderRecord.arriveTime">
                      <el-date-picker v-model="workOrderRecord.arriveTime" type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 175px;">
                      </el-date-picker>
                    </div>
                  </template>
                </el-step>
                <el-step v-if="workOrderRecord.recordState >= 2 && workOrderRecord.workOrderType === 2"
                  :title="$t('workOrder.arrived')" :description="workOrderRecord.arriveTime"></el-step>
                <el-step v-else :title="$t('workOrder.notArrived')"></el-step>

                <el-step
                  v-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 0 && workOrderRecord.workOrderType === 1"
                  :title="$t('workOrder.finished_')">
                  <template v-slot:description>
                    <div v-if="workOrderRecord.finishTime">
                      <el-date-picker v-model="workOrderRecord.finishTime" type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 175px;">
                      </el-date-picker>
                    </div>
                  </template>
                </el-step>
                <el-step
                  v-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 0 && workOrderRecord.workOrderType === 2"
                  :title="$t('workOrder.finished_')" :description="workOrderRecord.finishTime"></el-step>
                <el-step v-else-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 1"
                  :title="$t('workOrder.recovery')" :description="workOrderRecord.finishTime"></el-step>
                <el-step v-else-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 2"
                  :title="$t('workOrder.twoAlarmCompleted')" :description="workOrderRecord.finishTime"></el-step>
                <el-step v-else :title="$t('workOrder.notFinished')"></el-step>

                <el-step v-if="workOrderRecord.recordState >= 4 && workOrderRecord.confirmState === 0"
                  :title="$t('workOrder.confirmed_')" :description="workOrderRecord.confirmTime"></el-step>
                <el-step v-else-if="workOrderRecord.recordState >= 4 && workOrderRecord.confirmState === 1"
                  :title="$t('workOrder.autoConfirmed')" :description="workOrderRecord.confirmTime"></el-step>
                <el-step v-else :title="$t('workOrder.notConfirmed')"></el-step>

                <el-step v-if="workOrderRecord.recordState === 5" :title="$t('workOrder.cancelled')"></el-step>
              </el-steps>
            </div>
            <div style="width:100%;margin-top: 20px">
              <div style="width:70%;float: left;">
                <div v-if="workOrderRecord.id !== 0" class="vm-separate">
                  <el-form-item v-if="workOrderRecord.id !== 0" :label="$t('workOrder.workOrderNo')" prop="workOrderNo">
                    <span>
                      <el-input v-model="workOrderRecord.workOrderNo" disabled></el-input>
                    </span>
                  </el-form-item>
                  <el-form-item :label="$t('workOrder.alarmTime')">
                    <span>
                      <el-input v-model="workOrderRecord.alarmTime" disabled></el-input>
                    </span>
                  </el-form-item>
                </div>
                <div v-if="workOrderRecord.id === 0 || workOrderRecord.workOrderType === 1" class="vm-separate">
                  <el-form-item :label="$t('workOrder.faultTime')" prop="faultTime">
                    <el-date-picker v-model="workOrderRecord.faultTime" type="date" value-format="yyyy-MM-dd"
                      :placeholder="$t('common.pleaseSelect')" style="width: 100%;">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item :label="$t('workOrder.callTime')" prop="callTime">
                    <el-date-picker v-model="workOrderRecord.callTime" type="datetime"
                      :placeholder="$t('common.pleaseSelect')" style="width: 100%"
                      value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                  </el-form-item>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('workOrder.alarmReportedBy')" prop="repairPeople">
                    <el-input v-model="workOrderRecord.repairPeople" :placeholder="$t('common.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('workOrder.alarmPeopleTel')" prop="repairPhone">
                    <el-input v-model="workOrderRecord.repairPhone" :placeholder="$t('common.pleaseEnter')"></el-input>
                  </el-form-item>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('workOrder.alarmType')" prop="alarmType">
                    <el-select v-if="workOrderRecord.workOrderType === 1 || workOrderRecord.id === 0"
                      v-model="workOrderRecord.alarmType" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                      <el-option v-for="item in alarmTypeList1" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                    <el-select v-if="workOrderRecord.workOrderType === 2" v-model="workOrderRecord.alarmType"
                      :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                      <el-option v-for="item in alarmTypeList2" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="workOrderRecord.id !== 0" :label="$t('workOrder.acceptor')" prop="acceptorName">
                    <span><el-input v-model="workOrderRecord.acceptorName" disabled></el-input></span>
                  </el-form-item>
                  <el-form-item v-if="workOrderRecord.id === 0" :label="$t('workOrder.repairMethod')">
                    <span v-if="workOrderRecord.repairMethod === 1"><el-input :value="$t('workOrder.selfRepair')"
                        disabled></el-input></span>
                    <span v-else-if="workOrderRecord.repairMethod === 3"><el-input :value="$t('workOrder.appRepair')"
                                                                                   disabled></el-input></span>
                    <span v-else><el-input :value="$t('workOrder.phoneCall')" disabled></el-input></span>
                  </el-form-item>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('workOrder.inspection')" prop="isYearFault">
                    <el-select v-model="workOrderRecord.isYearFault" :placeholder="$t('common.pleaseSelect')"
                      style="width: 100%">
                      <el-option v-for="item in isYearFaultList" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="workOrderRecord.id !== 0" :label="$t('workOrder.repairMethod')">
                    <span v-if="workOrderRecord.repairMethod === 1"><el-input :value="$t('workOrder.selfRepair')"
                        disabled></el-input></span>
                    <span v-else-if="workOrderRecord.repairMethod === 3"><el-input :value="$t('workOrder.appRepair')"
                                                                                   disabled></el-input></span>
                    <span v-else><el-input :value="$t('workOrder.phoneCall')" disabled></el-input></span>
                  </el-form-item>
                  <el-form-item v-if="workOrderRecord.id === 0" :label="$t('elevator.faultCode')" prop="faultCode">
                    <el-input v-model="workOrderRecord.faultCode"></el-input>
                  </el-form-item>
                </div>
                <div v-if="workOrderRecord.id === 0 || workOrderRecord.workOrderType === 1" class="vm-separate">
                  <el-form-item :label="$t('workOrder.callRecord')" prop="callRecord">
                    <el-input v-model="workOrderRecord.callRecord" type="textarea"
                      :placeholder="$t('workOrder.faultDesc')"></el-input>
                  </el-form-item>
                  <div></div>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('workOrder.elevator')" prop="elevatorId">
                    <el-input v-model="workOrderRecord.elevatorName" :placeholder="$t('common.pleaseSelect')" readonly>
                      <el-button slot="append" icon="el-icon-search" @click="$refs.selectElevator.open()"></el-button>
                    </el-input>
                    <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
                  </el-form-item>
                  <el-form-item v-if="workOrderRecord.id !== 0" :label="$t('elevator.faultCode')" prop="faultCode">
                    <el-input v-model="workOrderRecord.faultCode"></el-input>
                  </el-form-item>
                  <div v-if="workOrderRecord.id === 0"></div>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('elevator.useUnit')" prop="clientId">
                    <el-input v-model="workOrderRecord.useUnitName"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('elevator.projectName')" prop="projectName">
                    <el-input v-model="workOrderRecord.projectName"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('workOrder.contactPerson')" prop="contactPerson">
                    <el-input v-model="workOrderRecord.contactPerson"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('workOrder.contactTel')" prop="contactTel">
                    <el-input v-model="workOrderRecord.contactTel"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                </div>
                <div class="vm-separate">
                  <el-form-item :label="$t('elevator.maintWorker') + '1'" prop="maintName">
                    <el-input v-model="workOrderRecord.maintName"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('elevator.maintWorker') + '2'" prop="maintName1">
                    <el-input v-model="workOrderRecord.maintName1"
                      :placeholder="$l(isPlaceholder.hasValueEn, isPlaceholder.hasValue)" readonly disabled></el-input>
                  </el-form-item>
                </div>
                <el-row>
                  <div style="float: left">
                    <el-button type="text" @click="$refs.onSelectMaintEmp.open()">
                      <span style="font-size: 16px"> + {{ $t("workOrder.servicePersonnel") }}</span>
                    </el-button>
                    <user-select ref="onSelectMaintEmp" @select="onSelectMaintEmp"></user-select>
                  </div>
                  <el-table :data="workOrderRecord.workOrderPersonnels" max-height="200" border stripe
                    style="width: 100%">
                    <table-column prop="userName" :label="$t('user.name')"></table-column>
                    <table-column prop="userMobile" :label="$t('user.phone')"></table-column>
                    <table-column :label="$t('common.operate')" width="120px" align="center" :tooltip="false">
                      <template #default="scope">
                        <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                          {{ $t("common.remove") }}
                        </el-button>
                      </template>
                    </table-column>
                  </el-table>
                </el-row>
              </div>
              <div
                style="width:27%;min-height: 400px;max-height: 560px; overflow-y :auto;overflow-x: hidden; float: right;border: solid 1px #DCDFE6;border-radius: 10px">
                <h3 style="text-align:center;">{{ $t("workOrder.emergencyRepairRecord") }}</h3>
                <div v-for="(workOrderStateNote, index) in workOrderRecord.workOrderStateNotes" :key="index"
                  style="width: 100%;">
                  <div v-if="workOrderStateNote.userId === 0" style="width: 25%;float: left;margin: 10px">
                    <el-tag>{{ $t("workOrder.system") }}</el-tag>
                  </div>
                  <div v-else style="width: 25%;float: left;margin: 10px" :title="workOrderStateNote.userName">
                    <el-tag>
                      {{
                        workOrderStateNote.userName.length > 5
                          ? workOrderStateNote.userName.substring(0, 5) + " .."
                          : workOrderStateNote.userName
                      }}
                    </el-tag>
                  </div>
                  <div style="width: 55%;float: right;margin: 10px auto">{{ workOrderStateNote.datetime }}</div>
                  <div style="width: 100%;float: left;margin-left: 10px;">
                    <el-tag v-if="workOrderStateNote.note === '有人'" type="danger">
                      {{ $t("workOrder.hasPeople") }}
                    </el-tag>
                    <el-tag v-else-if="workOrderStateNote.note === '无人'" type="success">
                      {{ $t("workOrder.noPeople") }}
                    </el-tag>
                    <el-tag v-else-if="workOrderStateNote.note.includes('->')" type="warning">
                      {{ workOrderStateNote.note }}
                    </el-tag>
                    <el-tag v-else type="info">{{ workOrderStateNote.note }}</el-tag>
                  </div>
                  <div
                    style="width: 100%;float: left;margin-left: 10px;margin-top:10px;border-bottom: solid 1px #DCDFE6;">
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('workOrder.faultRecords')" name="third">
            <div class="vm-separate" style="width: 100%">
              <el-table :data="workOrderRecord.faultRecords" stripe>
                <table-column prop="faultCode" :label="$t('workOrder.faultNo')"></table-column>
                <table-column prop="faultDesc" :label="$t('workOrder.faultType')">
                  <template #default="scope">
                    <span v-if="scope.row.faultDesc === '000000'">{{ $t("workOrder.unKnowFault") }}</span>
                    <span v-else>{{ scope.row.faultDesc }}</span>
                  </template>
                </table-column>
                <table-column prop="faultTime" :label="$t('workOrder.faultTime')"></table-column>
                <table-column v-if="false" prop="sendTime" :label="$t('workOrder.mainBoardTime')"></table-column>
                <table-column prop="faultFloor" :label="$t('workOrder.faultStorey')"></table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">{{
        $t("common.save")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ElevatorSelect from "../elevator/ElevatorSelect.vue";
import UserSelect from "../user/UserSelect.vue";

const moduleName = "workOrders";
const moduleName_useUnit = "useUnits";
const moduleName_elevator = "elevators";

export default {
  components: { ElevatorSelect, UserSelect },
  data() {
    return {
      submitLoading: false,
      saveDisabled: false,
      dialogVisible: false,
      contentLoading: false,
      activeTab: "first",
      stepActive: 1,
      alarmTypeList1: [
        { value: 4, label: this.$t("workOrder.deviceReasonTrapped") },
        { value: 5, label: this.$t("workOrder.deviceReasonSafe") },
        { value: 6, label: this.$t("workOrder.elevatorDamageOrFault") },
        { value: 7, label: this.$t("workOrder.minorIssues") },
        { value: 8, label: this.$t("workOrder.userReasonNoTrapped") },
        { value: 9, label: this.$t("workOrder.userReasonTrapped") }],
      alarmTypeList2: [
        { value: 0, label: this.$t("workOrder.alarmType_0"), },
        { value: 1, label: this.$t("workOrder.alarmType_1"), },
        { value: 2, label: this.$t("workOrder.alarmType_2"), },
        { value: 3, label: this.$t("workOrder.alarmType_3"), }],
      isYearFaultList: [{ value: 0, label: this.$t("common.no") }, { value: 1, label: this.$t("common.yes") }],
      isPlaceholder: {
        hasValue: "请选择电梯",
        hasValueEn: "workOrder.pleaseSelect",
      },
      workOrderRecord: {
        id: 0,
        callRecord: "",
        callTime: "",
        faultTime: "",
        workOrderNo: "",
        repairPeople: "",
        repairPhone: "",
        faultCode: "",
        alarmType: "",
        isYearFault: 0,
        acceptorName: "",
        acceptTime: "",
        alarmTime: "",
        arriveTime: "",
        finishTime: "",
        confirmTime: "",
        confirmState: -1,
        recordState: -1,
        finishState: -1,
        clientId: 0,
        useUnitName: "",
        projectName: "",
        repairMethod: "2",
        elevatorId: 0,
        workOrderType: "",
        elevatorName: "",
        maintName: "",
        maintName1: "",
        contactPerson: "",
        contactTel: "",
        alarmSolution: "",
        note: "",
        alarmReason: "",
        workOrderPersonnels: [],
        workOrderStateNotes: [],
        faultRecords: [],
        faultSetting: {
          solutionSetting: {
            id: 0,
            faultId: "",
            reason: "",
            solution: "",
          },
          id: 0,
          code: "",
          faultId: "",
        },
      },
      ruleValidate: {
        faultTime: [
          { required: true, message: this.$t("workOrder.faultTime") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        callTime: [
          { required: true, message: this.$t("workOrder.callTime") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        callRecord: [
          { required: true, message: this.$t("workOrder.callRecord") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        alarmType: [
          { required: true, message: this.$t("workOrder.alarmType") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        isYearFault: [
          { required: true, message: this.$t("workOrder.inspection") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        elevatorId: [
          {
            type: "number",
            required: true,
            min: 1,
            message: this.$t("elevator.elevator") + this.$t("common.tip.notNull"),
            trigger: "blur, change",
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.workOrderRecord.id === 0 ? this.$t("common.add") + " " + this.$t("workOrder.workOrder") : this.$t("common.edit") + " " + this.$t("workOrder.workOrder");
    },
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.workOrderRecord.id = id;
      if (id > 0) {
        this.getData();
      } else {
        let arr = this.getDate(new Date());
        this.workOrderRecord.faultTime = arr[0];
        this.workOrderRecord.callTime = arr[0] + " " + arr[1];
      }
    },
    getDate(now) {
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");

      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");
      second = second.toString().padStart(2, "0");

      let yearTime = `${year}-${month}-${date}`;
      let hourTime = `${hour}:${minute}:${second}`;

      let arr = [];
      arr.push(yearTime, hourTime);
      return arr;
    },
    getData() {
      this.calculateValue();
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName, this.workOrderRecord.id).then(res => {
        this.workOrderRecord = Object.assign(this.workOrderRecord, res.data);
        if (!this.$i18n.isCn) {
          for (let i = 0; i < this.workOrderRecord.workOrderStateNotes.length; i++) {
            if (this.workOrderRecord.workOrderStateNotes[i].note === "有人") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Has People";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "无人") {
              this.workOrderRecord.workOrderStateNotes[i].note = "No People";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修报警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair The Alarm";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修接警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Called";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修到场") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Be Present";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修完工") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repairing Completion";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修确认") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Confirmation";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修撤销") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Cancel";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修暂停") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Suspended";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修恢复") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Back";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动转检修") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatic To Overhaul";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Automatic";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "故障转检修") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Fault To Overhaul";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Fault";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警发送A0") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Send A0";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁打开") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Received A0 Door Lock Open";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "查询电梯状态") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Query Elevator Status";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁闭合") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm received A0 door lock closed";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修二次报警完工") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Emergency Repair Secondary Alarm Completed";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动确认") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatic Confirmation";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动撤销转维保记录") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatically Cancel The Transfer Maintenance Record";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "警铃报警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Bell";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "严重故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Critical Failure";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Auto";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Auto Failure";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul Automatically";
            }
          }
        }
        this.initStepActive();
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    calculateValue() {
      if (!this.workOrderRecord.contactPerson || !this.workOrderRecord.contactTel || !this.workOrderRecord.maintName || !this.workOrderRecord.maintName1) {
        this.isPlaceholder.hasValue = "无";
        this.isPlaceholder.hasValueEn = "None";
      }
    },
    initStepActive() {
      if (this.workOrderRecord.recordState === 1) {
        this.stepActive = 2;
      }
      if (this.workOrderRecord.recordState === 2) {
        this.stepActive = 3;
      }
      if (this.workOrderRecord.recordState >= 3) {
        this.stepActive = 4;
      }
      if (this.workOrderRecord.recordState >= 4) {
        this.stepActive = 5;
      }
      if (this.workOrderRecord.recordState === 5) {
        this.stepActive = 6;
      }
    },
    handleSubmit() {
      if (this.workOrderRecord.workOrderPersonnels.length === 0) {
        this.$message.error("请选择服务人员");
        return;
      }
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.save(moduleName, this.workOrderRecord).then(res => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success", this.workOrderRecord.id);
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
    getElevator() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName_elevator, this.workOrderRecord.elevatorId).then(res => {
        let data = res.data;
        this.workOrderRecord.maintName = data.maintEmpName;
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.saveDisabled = false;
      this.contentLoading = false;
      this.$refs.formValidate.resetFields();
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    onSelectElevator(row) {
      if (row.id !== this.workOrderRecord.elevatorId) {
        this.workOrderRecord.elevatorId = row.id;
        this.workOrderRecord.elevatorName = row.name;
        this.workOrderRecord.useUnitName = row.useUnitName;
        this.workOrderRecord.clientId = row.useUnitId;
        this.workOrderRecord.projectName = row.projectName;
        this.isPlaceholder.hasValue = "无";
        this.isPlaceholder.hasValueEn = "None";
        console.log(row);
        this.$api.getById(moduleName_useUnit, row.useUnitId).then(res => {
          let data = res.data;
          this.workOrderRecord.contactPerson = data.person;
          this.workOrderRecord.contactTel = data.contactTel;
        });
        this.getElevator();
      }
    },
    onSelectSolutionSetting(row) {
      this.workOrderRecord.alarmReason = row.id;
      this.workOrderRecord.faultSetting.solutionSetting.id = row.id;
      this.workOrderRecord.faultSetting.solutionSetting.reason = row.reason;
      this.workOrderRecord.faultSetting.solutionSetting.solution = row.solution;
    },
    deleteRow(index) {
      this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        let rows = this.workOrderRecord.workOrderPersonnels;
        rows.splice(index, 1);
      });
    },
    onSelectMaintEmp(row) {
      let flag = false;
      for (let item of this.workOrderRecord.workOrderPersonnels) {
        if (item.userId === row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.workOrderRecord.workOrderPersonnels.push({ userId: row.id, userName: row.name, userMobile: row.phone });
      } else {
        this.$message.error(this.$t("workOrder.tip.cantRepeat"));
      }
    },
  },
};
</script>
<style>
.el-textarea__inner {
  height: 20px;
}
</style>
